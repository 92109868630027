enum Export {
  Ambassadors = 'ambassadors',
  Orders = 'orders',
  OrdersStatistics = 'ordersStatistics',
  PaidOrdersAndUsersStatistics = 'paidOrdersAndUsersStatistics',
  Products = 'products',
  PromoCodes = 'promoCodes',
  RecommendationSupplements = 'recommendationSupplements',
  SalesStatistics = 'salesStatistics',
  Suppliers = 'suppliers',
  Users = 'users'
}

export default Export
